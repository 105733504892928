












































































import { Component } from 'vue-property-decorator';
import BaseComponent from '../components/BaseComponent';
import { BulkImportItem } from '../store/models';
import * as features from '../lib/feature-constants';

@Component
export default class Reports extends BaseComponent {
  protected baseRoute: string = '/bulk-import';
  protected feature: string = features.allowImport;

  private bulkImportItems: BulkImportItem[] = [
    { description: 'Clients', path: 'clients', feature: features.importClients, mandatory: [ 'Client Name', 'Client ID' ], optional: [ 'Civic Address', 'City', 'Postal Code', 'Province', 'Contact Name', 'Phone Number', 'Email' ], notes: [] },
    { description: 'Payees', path: 'payees', feature: features.importPayees, mandatory: [ 'Payee Name', 'Payee ID' ], optional: [ 'Civic Address', 'City', 'Postal Code', 'Province', 'Contact Name', 'Phone Number', 'Email' ], notes: [ 'Upon successful loading of Payees – the Primary Expense Class must be set on each Payee. The system defaults each new Payee to a ‘Service’ provider however you should change this classification before Expenses are loaded against these Payees. The Primary Expense Classes include ‘Service’, ‘Goods’, ‘Equipment’ and ‘Contributions’. These can be set on the individual Vendor records in the Master Vendor list found in the dropdown menu in the top right-hand corner.', 'If a Nisto Profile exists for the Vendor loaded in this step – you may make the connection from within the Vendor record from the ‘Master Vendor list’.' ] },
    { description: 'Projects', path: 'projects', feature: features.importProjects, mandatory: [ 'Project Name', 'Project ID', 'Client Name', 'Purchase Order ID', 'Amount ($)', 'Start Date' ], optional: [ 'Province', 'Discipline' ], notes: [] },
    { description: 'Expenses', path: 'expenses', feature: features.importExpense, mandatory: [ 'Payee Name', 'Amount ($)', 'Transaction Date', 'Project Name' ], optional: [], notes: [ 'Once Expenses are loaded and associated with a Payee and a Project – you can now navigate to a particular project. Select the Payees tab, select the Vendor by choosing the pencil icon and you may flag this Payee as being local to the Project.', 'Once Expenses are loaded and associated with a Payee and a Project – you can now navigate to a particular project. Select the Payees tab, select the Vendor by choosing the pencil icon and you may flag this Payee as being selected based on their relationship with an Indigenous Community.' ] },
    { description: 'Employment', path: 'employment', feature: features.importEmployment, mandatory: [ 'Date', 'Project Name', 'Rate / Hour', 'Hours Worked', 'Gross Wages', 'Occupation', 'diversity.me Code' ], optional: [], notes: [] },
  ];

  private navImport(id: string) {
    const path: string = '/' + id + '/import';
    this.$router.push({ path });
  }
}
